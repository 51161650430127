
$full-drawer-size: 220px;
$medium-drawer-size: 70px;
$mobile-drawer-size: 0px;
$popover-drawer-size: 220px;

// Pre-Auth Layout 

.pre-auth-header-container {
    background-color: $blueColor1;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.3);
}

.pre-auth-header-left-area {
    margin: auto;
    margin-left: 15px;
    margin-right: 5px;
    padding: 0px;
    display: flex;
    flex-direction: row;
}

.pre-auth-header-right-area {
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
    width: auto;
    display: flex;
    flex-direction: row;
}

.pre-auth-header-text {
    font-family: "Open Sans";
    font-size: 19px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: #E4E7E9;
    background-color: transparent;
    border: none;
}

.pre-auth-header-text-active {
    font-weight: 600;
    color: #FFFFFF;
}


@media (max-width:  $mobileBreakpoint) {
    .pre-auth-header-left-area {
        margin-left: 10px;
        margin-right: 5px;
    }
    
    .pre-auth-header-right-area {
        margin-right: 10px;
        margin-left: 5px;
    }

    .pre-auth-header-text {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 10px;
        padding-right: 10px;
    }
}


// Auth Layout

.auth-root {
    background-color: #E3E7EB;
    margin: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.root {
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 8px;
    display: none;
}

:hover::-webkit-scrollbar {
    width: 8px;
    display: block;
}

::-webkit-scrollbar-track {
    background: #E3E7EB;
    border-radius: 4px;
    height: 100%;
}

::-webkit-scrollbar-thumb {
    background: $greyColor3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 4px     rgba(0,0,0,0.3);
}

.auth-body {
    margin: 0;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.auth-main {
    background-color: #E3E7EB;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
}




.auth-full {
    margin-left: $full-drawer-size;
}

.auth-medium {
    margin-left: $medium-drawer-size;
}

.auth-mobile {
    margin-left: $mobile-drawer-size;
}

.auth-popover-medium {
    margin-left: $medium-drawer-size;
}

.auth-popover-mobile {
    margin-left: $mobile-drawer-size;
}


// Auth Header

.auth-header-container {
    background-color: $blueColor1;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 99;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.3);
}

.auth-header-left-area {
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
}

.auth-header-left-area-full {
    width: $full-drawer-size;
    margin-left: 8px;
    margin-right: 0px;
}

.auth-header-left-area-medium {
    // width: $medium-drawer-size;
    // margin-left: 4px;
    // margin-right: 10px;
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
}

.auth-header-left-area-mobile {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
}



.auth-header-menu-button {
    background: none;
    border: none;
    color: #fff;
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: auto;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    cursor: pointer;

}

.auth-header-middle-area {
    margin: auto;
    margin-left: 0px;
    width: auto;
    display: flex;
    flex-direction: row;
}

.auth-header-right-area {
    margin: auto;
    margin-right: 15px;
    margin-left: 15px;
    width: auto;
    display: flex;
    flex-direction: row;
}

.auth-header-hideable-area {
    height: auto;
    margin: auto;
    margin-left: 0px; 
    margin-right: 0px;
}

.auth-header-hideable-area-hidden {
    display: none;
}

.auth-header-hideable-area-show {
    display: block;
}


// Auth Navigation

.auth-navigation-sidebar-container {
    background-color: $greyColor6;
    border-right: 1px #777777 solid;
    margin: 0;
    height: auto;
    overflow: auto;
    font-family: 'Open Sans';
    top: 50px;
    left: 0;
    right: auto;
    bottom: 0;
    position: fixed;

    &:hover::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar {
        display: block;
    }
}

.auth-navigation-sidebar-container-full {
    min-width: $full-drawer-size;
}

.auth-navigation-sidebar-container-medium {
    background-color: $greyColor6;
    border-right: 1px #777777 solid;
    margin: 0;
    height: calc(100vh - 50px);
    position: fixed;
    overflow: overlay;
    font-family: 'Open Sans';
    width: $medium-drawer-size;
}

.sidebar-medium-list {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: none;
    margin: 0px;
    
}

.sidebar-medium-list-item-box {
    padding: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;

    &:hover {
        color: #07689F ;
        font-weight: 500;
    }
}

.sidebar-medium-list-item-box-active {
    color: #07689F ;
    font-weight: 600;
}


.auth-navigation-sidebar-container-mobile {
    max-width: $mobile-drawer-size;
}


.auth-navigation-sidebar-container::-webkit-scrollbar {
    width: 6px;
    display: none;
}

.auth-navigation-sidebar-container:hover::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.auth-navigation-sidebar-container::-webkit-scrollbar-track {
    background: #DFDFDF;
    border-radius: 3px;
    height: 100%;
}

.auth-navigation-sidebar-container::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px     rgba(0,0,0,0.3);
}


.auth-navigation-sidebar-container-medium::-webkit-scrollbar {
    width: 2px;
    display: none;
}

.auth-navigation-sidebar-container-medium:hover::-webkit-scrollbar {
    width: 2px;
    display: block;
}

.auth-navigation-sidebar-container-medium::-webkit-scrollbar-track {
    background: #DFDFDF;
    border-radius: 1px;
    height: 100%;
}

.auth-navigation-sidebar-container-medium::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 1px     rgba(0,0,0,0.3);
}


.mobile-sidebar-backdrop {
    display: block;
    position: fixed;
    overflow-y: overlay;
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0px;
    background: rgba(0, 0, 0, 0.5);
    background-color: rgba(0,0,0,0.5);
    z-index: 1300;
    -webkit-tap-highlight-color: transparent;
    border: none;
}

.mobile-sidebar-backdrop-clickable {
    margin: 0;
    padding: 0;
    margin-left: $full-drawer-size;
    width: calc(100% - 220px ); 
    height: 100%; 
    background: none;
    background-color: none;
    z-index: 1301;
    -webkit-tap-highlight-color: transparent;
    border: none;
    transition: 100ms;
}


.mobile-sidebar-backdrop-hidden {
    display: block;
    opacity: 0;
}

.mobile-sidebar-backdrop-show {
    display: block;
    opacity: 1;
}

.mobile-sidebar-drawer {
    margin: 0px;
    position: fixed;
    overflow: auto;
    top: 0px;
    left: 0px;
    right: auto;
    height: 100%;
    width: 70%;
    max-width: $full-drawer-size;
    background-color: $greyColor6;
    border-right: 1px #777777 solid;
    display: flex;
    flex-direction: column;
    z-index: 1302;
    transition: 500ms;
    -webkit-overflow-scrolling: touch;
}

.mobile-sidebar-drawer-hidden {
    display: none;
    left: -$full-drawer-size;
}

.mobile-sidebar-drawer-show {
    display: flex;
    left: 0px;
}

.sidebar-list::-webkit-scrollbar {
    width: 6px;
    display: none;
}

.sidebar-list:hover::-webkit-scrollbar {
    width: 6px;
    display: block;
}

.sidebar-list::-webkit-scrollbar-track {
    background: #DFDFDF;
    border-radius: 3px;
    height: 100%;
}

.sidebar-list::-webkit-scrollbar-thumb {
    background: #777777;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px     rgba(0,0,0,0.3);
}



// Custom List Area
.sidebar-list {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 30px;
    list-style: none;
    overflow: overlay;
}

.sidebar-popover-list {
    margin: 0;
    padding: 0;
    padding-top: 60px;
    padding-bottom: 30px;
    list-style: none;
    overflow: overlay;
}

.sidebar-list-category-header {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 1.25rem;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: $blueColor1;
        font-weight: 600;
        background-color: $greyColor1;
    }
}

.sidebar-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 1.25rem;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: $blueColor1;
        font-weight: 600;
        background-color: $greyColor1;
    }
}

.sidebar-list-item-icon {
    min-width: auto;
    margin-right: 12px;
    display: inline-flex;
    flex-shrink: 0;
}

.sidebar-list-item-active {
    color: $blueColor1;
    font-weight: 600;
}

.sidebar-list-item-text {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 16px;
    display: block;
    line-height: 1.5;
}

.sidebar-list-item-text-active {
    color: $blueColor1;
    font-weight: 600;
}


.sidebar-list-prohelper-header {
    top: 0px;
    left: 0px;
    height: 50px;
    width: 220px;
    position: fixed;
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: auto;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.25rem;
    color: #fff;
    text-align: left;
    background-color: $blueColor1;
    display: flex;
    z-index: 101;
    flex-direction: row;
}

.sidebar-popover-menu-button {
    background: none;
    border: none;
    color: #fff;
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: auto;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    cursor: pointer;
}

@media (min-width:  $mobileBreakpoint) {
    .sidebar-list-prohelper-header {
        padding-left: 18px;
        padding-right: 18px;
    }
}



// Custom Popover ProHelper Menu Area