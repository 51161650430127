.UsersListCellHousing {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 0px;
    border-radius: 10px;
}

.UsersListCell {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 5px;
    background-color: white;
    padding: 10px;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }

}

.UsersListCellProfileArea {
    width: fit-content;
    height: 100%;
}

.UsersListCellProfileImage {
    width: 50px;
    height: 50px;
    border: 1px solid $darkBlueColor;
    border-radius: 50%;
    margin-right: 10px;
}

.UsersListCellContents {
    width: 100%;

    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}



// Roles List Cell CSS

.RolesListCellHousing {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-top: 5px;
    border-radius: 10px;
}

.RolesListCell {
    width: 100%;
    display: block;
    border: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 5px;
    background-color: white;
    padding: 0px;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}

.RolesListCellContents {
    width: 100%;
    padding-bottom: 10px;

    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}


.RolesListCell_Divider {
    
    height: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: $darkBlueColor1;
}


// Roles Detail User Cell CSS

.RoleDetailUsersListCell_Housing {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 10px;
}

.RoleDetailUsersListCell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid $darkBlueColor;
    border-radius: 5px;
    background-color: white;
    padding: 10px;

    &:hover {
        border: 2px solid $blueColor1;
        padding: 9px;
    }
}

.RoleDetailUsersListCell_ProfileArea {
    width: fit-content;
    height: 100%;
}

.RoleDetailUsersListCell_ProfileImage {
    width: 50px;
    height: 50px;
    border: 1px solid $darkBlueColor;
    border-radius: 50%;
    margin-right: 10px;
}

.RoleDetailUsersListCell_Contents {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
