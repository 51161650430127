
// Big Title Typography
$bigTitleFontFamily: "Open Sans";
$bigTitleFontSize: 28px;
$bigTitleFontSizeDesktop: 42px;
$bigTitleFontWeight: 700;

// Title Typography
$titleFontFamily: "Open Sans";
$titleFontSize: 28px;
$titleFontSizeDesktop: 34px;
$titleFontWeight: 600;
$quickLinksFontSize: 20px;
$quickLinksFontSizeDesktop: 22px;

// Title2 Typography
$title2FontFamily: "Open Sans";
$title2FontSize: 24px;
$title2FontSizeDesktop: 32px;
$title2FontWeight: 600;

// Sub Title Typography
$subTitleFontFamily: "Open Sans";
$subTitleFontSize: 20px;
$subTitleFontSizeDesktop: 22px;
$subTitleFontWeight: 600;

// Sub Title Small Typography
$subTitleSmallFontFamily: "Open Sans";
$subTitleSmallFontSize: 16px;
$subTitleSmallFontSizeDesktop: 18px;
$subTitleSmallFontWeight: 600;


// Body Typography
$bodyFontSize: 15px;
$bodyFontSizeDesktop: 16px;
$bodyFontWeight: 500;
$bodyFontFamily: "Open Sans";
$buttonTextFontSize: 16px;

//Small Text Typography
$smallTextFontFamily: "Open Sans";
$smallTextFontSize: 12px;
$smallTextFontSizeDesktop: 14px;
$smallTextFontWeight: 500;


//Micro Text Typography
$microTextFontFamily: "Open Sans";
$microTextFontSize: 10px;
$microTextFontSizeDesktop: 12px;
$microTextFontWeight: 500;

// Button Typography
$largeButtonMobileSize: 21px;
$largeButtonDesktopSize: 25px;

// Box Title Typography
$whiteBoxTitleMobileSize: 22px;
$whiteBoxTitleDesktopSize: 28px;


// Titles
.BigTitle {
    font-family: $bigTitleFontFamily;
    font-size: $bigTitleFontSize;
    font-weight: $bigTitleFontWeight;
    margin: 0px;
}

.Title {
    font-family: $titleFontFamily;
    font-size: $titleFontSize;
    font-weight: $titleFontWeight;
    margin: 0px;
}

.Title2 {
    font-family: $title2FontFamily;
    font-size: $title2FontSize;
    font-weight: $title2FontWeight;
    margin: 0px;
}

.SubTitle {
    font-family: $subTitleFontFamily;
    font-size: $subTitleFontSize;
    margin: 0px;
    line-height: normal;
}

.SubTitleSmall {
    font-family: $subTitleSmallFontFamily;
    font-size: $subTitleSmallFontSize;
    margin: 0px;
    line-height: normal;
}

.SmallText {
    font-family: $smallTextFontFamily;
    font-size: $smallTextFontSize;
    line-height: normal;
}

.MicroText {
    font-family: $microTextFontFamily;
    font-size: $microTextFontSize;
    line-height: normal;
}

.ButtonText {
    font-family: $bodyFontFamily;
    font-size: $buttonTextFontSize;
    line-height: normal;
    letter-spacing: normal;
}


.BodyText {
    font-family: $bodyFontFamily;
    font-size: $bodyFontSize;
    line-height: normal;
}

// Colors
.BlueTint {
    color: $blueColor1;
}

.DarkBlueTint {
    color: $darkBlueColor;
}

.DarkGreyTint {
    color: $darkBlueColor1;
}

.DirtyWhiteTint {
    color: $dirtyWhiteColor;
}

.GreyTint {
    color: $greyColor4;
}

.RedTint {
    color: $redColor;
}

.DarkBlueTint2 {
    color: $darkBlueColor1;
}

.GreenTint {
    color: $greenColor;
}

.BlackTint {
    color: $blackColor;
}

.WhiteTint {
    color: $whiteColor;
}


// Font Weights
.SemiBoldText {
    font-weight: 600;
}

.BoldText {
    font-weight: 700;
}

.NormalText {
    font-weight: 500;
}


// Containers
.TitleContainer {
    margin: auto; 
    margin-top: 35px; 
    max-width: 90%; 
    align-content: center;
}

.SmallTextContainer {
    margin: auto; 
    margin-top: 15px; 
    max-width: 85%; 
    align-content: center;
    line-height: normal;
}

// Alignments
.TextAlignLeft {
    text-align: left;
}

.TextAlignCentered {
    text-align: center;
}

.TextAlignRight {
    text-align: right;
}

.WhiteBody {
    color: $whiteColor;
    font-family: $bodyFontFamily;
    font-size: $bodyFontSize;
    font-weight: $bodyFontWeight;
}

.ErrorBody {
    color: $redColor;
    font-family: $bodyFontFamily;
    font-size: $bodyFontSize;
    font-weight: $bodyFontWeight;
}

.LargeButtonText {
    margin: auto;
    color: $whiteColor;
    font-family: $bodyFontFamily;
    font-size: $largeButtonMobileSize;
    font-weight: $titleFontWeight;
    text-align: center;
    justify-self: center;
}

.WhiteBoxTitle {
    margin: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    color: $whiteColor;
    font-family: $titleFontFamily;
    font-size: $whiteBoxTitleMobileSize;
    font-weight: $titleFontWeight;
    text-align: left;
    justify-self: center;
    width: 85%;
    max-width: 600px;
}

.QuickLinksTitle {
    color: $blackColor;
    font-family: $titleFontFamily;
    font-size: $quickLinksFontSize;
    font-weight: $titleFontWeight;
    margin-bottom:0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


@media (min-width: $minDesktopWidth) {
    .LargeButtonText {
        font-size: $largeButtonDesktopSize;
    }

    .ErrorBody {
        font-size: $bodyFontSizeDesktop;
    }

    .BigTitle {
        font-size: $bigTitleFontSizeDesktop;
    }

    .Title {
        font-size: $titleFontSizeDesktop;
    }

    .Title2 {
        font-size: $title2FontSizeDesktop;
    }

    .SubTitle {
        font-size: $subTitleFontSizeDesktop;
    }

    .SubTitleSmall {
        font-size: $subTitleSmallFontSizeDesktop;
    }

    .BodyText {
        font-size: $bodyFontSizeDesktop;
    }

    .SmallText {
        font-size: $smallTextFontSizeDesktop;
    }

    .TitleContainer {
        margin-top: 70px;
    }

    .SmallTextContainer {
        margin-top: 30px; 
        max-width: 70%;
    }

    .WhiteBoxTitle {
        padding-left: 15px;
        margin-bottom: 15px;
        font-size: $whiteBoxTitleDesktopSize;
    }

    .QuickLinksTitle {
        font-size: $quickLinksFontSizeDesktop;
    }
}