
@mixin setSize($width: auto, $height: auto) {
    width: $width;
    height: $height;
};

@mixin setMaxSize($maxWidth: none, $maxHeight: none) {
    max-width: $maxWidth;
    max-height: $maxHeight;
};

@mixin alignHorizontallyCenter  {
    justify-content: center;
    text-align: center;
};
