// App CSS
.App {
    height: 100%;
    width: 100%;
    overflow: visible;
    background-color: #E3E7EB;
}

// Settings Masonry Grid CSS
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin: auto;
    margin-top: 30px;
    width: auto;
    max-width: 1300px;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */   
    margin-bottom: 20px;
}

@media (min-width: 500px) {
    .my-masonry-grid {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */   
        margin-bottom: 40px;
    }
}


// Settings Form Masonry Grid CSS
.settings-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin: auto;
    width: auto;
    max-width: 1300px;
}

.settings-masonry-grid_column {
    background-clip: padding-box;
}

/* Style your items */
.settings-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */   
    padding-right: 0px;
}

@media (min-width: 500px) {
    .settings-masonry-grid {
        margin-top: 0px;
    }
}

// Screen Detail Masonry Grid CSS
.screen-detail-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin: auto;
    width: auto;
}

.screen-detail-masonry-grid_column {
    background-clip: padding-box;
}

/* Style your items */
// .screen-detail-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */   
    
// }

@media (min-width: 500px) {
    .screen-detail-masonry-grid {
        margin: 0px 10px;
    }

}