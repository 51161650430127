// Colors
$blueColor1: #07689F;
$blueColor2: #07689f;
$blueColor3: #0A3F79;
$redColor: #DA3A3B;
$yellowColor: #F5CD00;
$greenColor: #2D9639;
$greyColor1: #CDCECF;
$greyColor2: #E1E1E1;
$greyColor3: #979797;
$greyColor4: #444444;
$greyColor5: #777777;
$greyColor6: #DFDFDF;
$greyColor7: #394453;
$offWhiteColor: #F1EFEE;
$backgroundColor: #E3E7EB;
$blackColor: #000;
$whiteColor: #fff;
$popUpBackgrounColor: rgba(0,0,0,0.70);

$darkBlueColor: #002D47;
$darkBlueColor1: #272F3A;
$dirtyWhiteColor: #E3E7EB;


$backgroundImage: linear-gradient(180deg, #0091FF 0%, #0071A9 99%);
$boxShadowColor: rgba(0,0,0,0.70);

$minTabletWidth: 800px;
$minDesktopWidth: 800px;

$mobileBreakpoint: 800px;
$tabletBreakpoint: 1330px;