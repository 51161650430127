.UsersListView {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $offWhiteColor;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


// Users Table
.UsersTableView {
    width: 100%;
}

.UsersTableView_Table {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.UsersTableView_TableHeader {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $offWhiteColor;
}

.UsersTableView_TableBody {
    width: 100%;
    border-radius: 10px;
}

.UsersTableView_TableHeaderRow {
    background-color: $offWhiteColor;
}

.UsersTableView_TableBodyRow {
    background-color: $whiteColor;

    &:hover {
        background-color: $offWhiteColor;
        cursor:  pointer;
        
        td {
            p {
                color: $blueColor1;
            }

            img {
                border-color: $blueColor1;
                border-width: 2px;
            }
        }
    }
}

.UsersTableView_TableHeaderCell {
    padding: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $darkBlueColor;
    vertical-align: middle;
}

.UsersTableView_TableDataCell {
    padding: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
    text-align: left;
    vertical-align: middle;
}

.UsersTableView_TableHeaderImageCell {
    padding: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid $darkBlueColor;
    vertical-align: middle;
}

.UsersTableView_TableDataImageCell {
    width: fit-content;
    height: 100%;
}

.UsersTableView_TableDataImage {
    width: 50px;
    height: 50px;
    border: 1px solid $darkBlueColor;
    border-radius: 50%;
    margin: 10px;
}



// Roles Table
.RolesTableView {
    width: 100%;
}

.RolesTableView_Table {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    border-collapse: collapse;
    padding-bottom: 50px;
}

.RolesTableView_TableHeader {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $offWhiteColor;
}

.RolesTableView_TableBody {
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.RolesTableView_TableHeaderRow {
    background-color: $offWhiteColor;
}

.RolesTableView_TableBodyRow {
    background-color: $whiteColor;

    &:hover {
        background-color: $offWhiteColor;
        cursor:  pointer;
        
        td {
            p {
                color: $blueColor1;
            }
        }
    }
}

.RolesTableView_TableHeaderCell {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid $darkBlueColor;
    vertical-align: middle;
}

.RolesTableView_TableDataCell {
    padding: auto;
    padding: 20px 15px;
    margin: auto;
    text-align: left;
    vertical-align: middle;

}

.RolesTableView_TableDataCell-Date {
    padding: auto;
    padding: 20px 15px;
    min-width: 105px;
    margin: auto;
    text-align: left;
    vertical-align: middle;

}


// Role Detail Info View CSS
.RoleDetailInfoView {
    padding: 15px;
    width: 100%;
    max-width: 100%;
}

.RoleDetailInfoView_Spacer {
    background-color: none;
    border: none;
    height: 10px;
} 


//Role Detail Users View CSS

.RoleDetailUsersView {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $offWhiteColor;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: auto;
    max-height: 365px;
    overflow-y: auto;
}