// Base Button
.Button {
    margin: auto;
    border: 1px solid #000;
    border-radius: 50%;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

// Forgot Password Button
.ForgotPasswordButton {
    @include alignHorizontallyCenter;
    background-color: transparent;
    border: none;
}

// Log In Button
.LogInButton {
    @extend .Button;
    height: 50px;
    width: 80%;
    max-width: 400px;
    margin-top: 15px;
    border-radius: 30px;

    &.Completed {
        background: $greenColor;
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
    
    &.NotCompleted {
        background: $greyColor3;
        box-shadow: 0;
    }

}

@media (min-width:  $minTabletWidth) {
    .LogInButton {
        margin-top: 30px;
        height: 60px;
    }
}

// Sign Out Button
.SignOutButton {
    @extend .Button;
    height: 50px;
    width: 80%;
    max-width: 400px;
    margin-top: 30px;
    border-radius: 30px;
    background: $redColor;
    box-shadow: 2px 2px 2px 0 $boxShadowColor;
}

// Sign Up 1 Button
.SignUp1Button {
    @extend .Button;
    height: 50px;
    width: 80%;
    max-width: 400px;
    margin-top: 5px;
    border-radius: 30px;
    padding: 0;

    &.Completed {
        background: $greenColor;
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }

}

@media (min-width:  $minTabletWidth) {
    .SignUp1Button {
        margin-top: 15px;
        height: 60px;
    }
}

// Sign Up Button
.SignUpButton {
    @extend .Button;
    @include alignHorizontallyCenter;
    height: 50px;
    width: 60%;
    max-width: 400px;
    margin-top: 30px;
    border-radius: 30px;
    background: $blueColor1;
    box-shadow: 2px 2px 2px 0 $boxShadowColor;
    margin-bottom: 50px;
}

@media (min-width:  $minTabletWidth) {
    .SignUpButton {
        width: 80%;
        height: 60px;
    }
}

// Submit Button
.SubmitButton {
    @extend .Button;
    height: 50px;
    width: 80%;
    max-width: 400px;
    margin-top: 15px;
    border-radius: 30px;

    &.Completed {
        background: $greenColor;
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
    
    &.NotCompleted {
        background: $greyColor3;
        box-shadow: 0;
    }

}

// Go Back Button
.GoBackButton {
    @extend .Button;

    margin-left: 20px;
    margin-right: 20px;
    border-radius: 30px;

    background: $whiteColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;

}

// Go Back Button
.DashboardButton {
    @extend .Button;

    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 30px;

    background: $greenColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;

}

@media (min-width:  $minTabletWidth) {
    .DashboardButton {
        margin-top: 0px;
    }
}

// Go Back Button
.ErrorSignOutButton {
    @extend .Button;

    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 30px;

    background: $redColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;

}

@media (min-width:  $minTabletWidth) {
    .ErrorSignOutButton {
        margin-top: 0px;
    }
}

.EditSettingsBoxButton {
    border-radius: 5px;
    background-color: $greenColor;
    border-color: $darkBlueColor1;
}

.DeleteSettingsBoxButton {
    border-radius: 5px;
    background-color: $redColor;
    border-color: $darkBlueColor1;
}

// Settings Outlined Button
.SettingsOutlinedButton {
    width: inherit;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid $darkBlueColor;
    border-radius: 5px;
    overflow: hidden;
    padding: 0px;

    &:hover {
        border: 2px solid $blueColor1;

        .p {
            color: $blueColor1;
        }
    }

    &:active {
        border: 1px solid $blueColor1;

        .p {
            color: $blueColor1;
        }
    }
}

.SettingsOutlinedButtonDiv {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: $whiteColor;
}

// Settings Red Outlined Button
.SettingsRedOutlinedButton {
    width: inherit;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid $redColor;
    border-radius: 5px;
    overflow: hidden;
    padding: 0px;

    .p {
        color: $redColor;
    }

    &:hover {
        border: 3px solid $redColor;

        .p {
            color: $redColor;
            font-size: 600;
        }
    }

    &:active {
        border: 3px solid $redColor;

        .p {
            color: $redColor;
            font-size: 600;
        }
    }
}


// No Button
.HiddenButton {
    margin: auto;
    padding: 0;
    background: none;
    border:none;
    width: 100%;
}

@media (min-width:  $minTabletWidth) {
    .SubmitButton {
        margin-top: 15px;
        height: 60px;
    }
}


// Text Button
.TextButton {
    margin: auto;
    padding: 0;
    background: none;
    border:none;
    text-align: center;
    justify-self: center;
}

// Other
.DisabledButton {
    opacity: 0.8;
}

.NotDisabledButton {
    opacity: 1;
}

.BoxActionDesktopButton {
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border: 1px solid $darkBlueColor;
    border-radius: 10px;
    background: $greenColor;
    display: flex;
    align-content: center;
    padding: 10px;
    margin: 5px;
}

.BoxActionMobileButton {
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border: 1px solid $darkBlueColor;
    border-radius: 50%;
    background: $greenColor;
    display: flex;
    align-content: center;
    padding: 10px;
    margin: 5px;
}

.ScreenHeaderActionButton {
    box-shadow: 0.5px 0.5px 0.5px 1 $boxShadowColor;
    border: 1px solid $darkBlueColor;
    border-radius: 50%;
    background: $greenColor;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 8px;
    font-weight: 600;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}

.ScreenHeaderMoreButton {
    box-shadow: 0.5px 0.5px 0.5px 1 $boxShadowColor;
    border: 1px solid $blueColor1;
    border-radius: 50%;
    background: $whiteColor;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 8px;
    font-weight: 600;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}

@media (min-width:  600px) {
    .ScreenHeaderActionButton {
        border-radius: 5px;
        padding: 8px 10px;
    }
    
    .ScreenHeaderMoreButton {
        border-radius: 5px;
        padding: 8px 10px;
    }

}


.ActionBox_HeaderButton {
    border-radius: 5px;
    background-color: $blueColor1;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-color: $darkBlueColor1;
    width: fit-content;
    padding-top: 6px;
    padding-left: 12px;
    padding-right: 6px;
    padding-bottom: 6px;
    display: flex;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}

.BoxFooterButton {
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-color: $darkBlueColor1;
    width: fit-content;
    padding: 10px 12px;
    display: block;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }

    &:disabled {
        box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    }
}




// Confimr Delete Account Button Area

.ConfirmDeleteAccountButtonContainer {
    width: 100%;
    text-align: center;
}



.ConfirmDeleteAccountButtonBack {
    @extend .Button;
    @include alignHorizontallyCenter;
    height: 50px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    border-radius: 10px;
    background: $greenColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    font-weight: 600;

    &:hover {
        p {
            font-weight: 700;
        }
    }
}

.ConfirmDeleteAccountButtonRed {
    @extend .Button;
    @include alignHorizontallyCenter;
    height: 50px;
    width: 100%;
    margin-top: 20px;
    max-width: 400px;
    border-radius: 10px;
    background: $whiteColor;

    p {
        color: $redColor;
        font-weight: 600;
    }

    &:hover {
        background: $redColor;
        p {
            color: $whiteColor;
            font-weight: 700;
        }
    }

    
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
}

@media (min-width:  $minTabletWidth) {
    .ConfirmDeleteAccountButtonBack {
        width: 60%;
        box-shadow: 2px 2px 2px 0 $boxShadowColor;
    }
    .ConfirmDeleteAccountButtonRed {
        width: 60%;
        box-shadow: 2px 2px 2px 0 $boxShadowColor;
    }

    .ConfirmDeleteAccountButtonContainer {
        margin-bottom: 30px;
    }

    .BoxActionDesktopButton {
        padding: 10px;
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}
