.ForgotPasswordForm {

    margin: auto;
    align-content: center;
    align-items: center;
    display: inline;

    label {
        margin: 0;
        align-self: auto;
    }

}

.LogInForm {

    margin: auto;
    align-content: center;
    align-items: center;
    display: inline;

    label {
        margin: 0;
        align-self: auto;
    }

}

.NewPasswordForm {

    margin: auto;
    align-content: center;
    align-items: center;
    display: flexbox;

    label {
        margin: 0;
        align-self: auto;
    }

}

.SignUpForm {

    margin: auto;
    align-content: center;
    align-items: center;
    display: flexbox;

    label {
        margin: 0;
        align-self: auto;
    }

}

.ButtonsArea {
    display: inline-block;
    width: 100%;
}

.inputBoxes {
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
}

.SettingsInputFormArea {
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.SetupAccountInputFormArea {
    margin: auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.SettingsInputFormAreaFlex {
    display: block;
}


// User Detail Account Form CSS
.UserDetailAccountForm_Container {
    padding: 10px;
    width: 100%;
    max-width: 100%;
}

.UserDetailAccountForm_Image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 1px solid $darkBlueColor;
}

.UserDetailAccountForm_TopArea {
    max-width: 100%;
    display: flex;
}

.UserDetailAccountForm_TopAreaLeftSection {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 95px;
}

.UserDetailAccountForm_TopAreaRightSection {
    padding-left: 10px;
    padding-right: 10px;
    overflow-wrap: break-word;
    white-space: initial;
    flex: 1 1 auto;

    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.UserDetailAccountForm_ContentsArea {
    display: block;
    padding: 10px;
}

.UserDetailAccountForm_ContainerSpacer {
    background-color: none;
    border: none;
    height: 5px;
}



// User Detail Contact Form CSS
.UserDetailContactForm_Container {
    padding: 15px;
    width: 100%;
    max-width: 100%;
}

.UserDetailContactForm_ContainerSpacer {
    background-color: none;
    border: none;
    height: 15px;
}   



// User Detail Role Form CSS
.UserDetailRoleForm_Container {
    padding: 15px;
    width: 100%;
    max-width: 100%;
}

.UserDetailRoleForm_ContainerSpacer {
    background-color: none;
    border: none;
    height: 10px;
}  


@media (min-width:  $minTabletWidth) {
    .SettingsInputFormArea {
        margin-left: 20px;
        margin-right: 20px;
    }

    .SettingsInputFormAreaFlex {
        display: flex;
    }

    .UserDetailAccountForm_Image {
        width: 100px;
        height: 100px;
    }

    .UserDetailContactForm_Container {
        padding: 20px;
    }

    .UserDetailContactForm_ContainerSpacer {
        height: 20px;
    }  

    .UserDetailRoleForm_Container {
        padding: 20px;
    }

    .UserDetailRoleForm_ContainerSpacer {
        height: 15px;
    }  
}