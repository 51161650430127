

.TableContainer {
    padding: 15px;
    overflow-x: auto;
    margin-right: auto;
    margin-left: auto;
}


.SettingsBoxTextContainer {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.ScreenHeaderHousing {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;

}

.ScreenHeaderLeftArea {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.ScreenHeaderRightArea {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 700px) {

    .TableContainer {
        padding: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 80%;
        max-width: 1200px;
    }

    .SettingsBoxTextContainer {
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .ScreenHeaderHousing {
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
    
    }
}