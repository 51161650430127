// Navigation
.SettingsBreadCrumbsContainer {
    padding-left: 20px;
    padding-top: 20px;
}


.SettingsBreadCrumbDivider {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    height: 1px;
    display: block;
    border: 0;
    border-top: 1px solid $darkBlueColor1;
}

// Text Fields
.SettingsTextFieldContainer {
    display: block;
    text-align: left;
    margin-top: 15px;
    flex-grow: 1;
}

.SettingsTextFieldContainerMultiLine {
    display: grid;
    text-align: left;
    margin-top: 15px;
    flex-grow: 1;
}


.SettingsTextFieldInput {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 5px;
    font-size: $bodyFontSizeDesktop;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
        border: 2px solid $blueColor1;
        padding: 11px;
    }

    &:focus {
        border: 2px solid $blueColor1;
        padding: 11px;
    }

    &:disabled {
        padding: 12px;
        border: 1px solid $darkBlueColor;
    }
}

.SettingsTextFieldInputMultiLine {
    min-width: 100%;
    min-height: 60px;
    max-height: 400px;
    margin-top: 10px;
    padding: 10px 12px;
    border-top: 1px solid $darkBlueColor;
    border-bottom: 1px solid $darkBlueColor;
    border-left: 1px solid $darkBlueColor;
    border-right: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 5px;
    font-size: $bodyFontSizeDesktop;
    box-sizing: border-box;
    overflow: auto;
    resize: vertical;


    &:hover {
        border-top: 2px solid $blueColor1;
        border-bottom: 2px solid $blueColor1;
        border-left: 2px solid $blueColor1;
        border-right: 2px solid $blueColor1;
    }


    &:focus {
        border-top: 2px solid $blueColor1;
        border-bottom: 2px solid $blueColor1;
        border-left: 2px solid $blueColor1;
        border-right: 2px solid $blueColor1;
        box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    }
}


.SettingsTextFieldLabel {
    padding-left: 10px;
    font-weight: 600;
}

.BoxSearchContainer {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin: 0;
    border: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0px 10px;

    &:hover {
        border: 2px solid $blueColor1;
    }
}

.BoxSearchContainerFocused {
    border: 2px solid $blueColor1;

    &:hover {
        box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;

    }
}

.BoxSearchInput {
    width: 240px;
    height: auto;
    padding: 12px 10px;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    overflow: hidden;
    font-size: 15px;

}

.BoxSortSelectContainer {
    display: block;
    text-align: left;
    margin-top: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 10px;
}

.BoxHeaderContainer {
    margin: auto;
    padding: 3px 12px;
    background-color: $offWhiteColor;
    border-bottom: 1px solid $darkBlueColor;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.CustomBackDrop {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 10000;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(74, 83, 105, 0.35);
    -webkit-tap-highlight-color: transparent;
    overflow: auto;
}


input::-webkit-input-placeholder {
    line-height:normal!important;
}

@media (min-width: 700px) {

    .SettingsBreadCrumbsContainer {
        padding-left: 40px;
        padding-top: 20px;
    }
    
    
    .SettingsBreadCrumbDivider {
        margin-left: 20px;
        margin-right: 20px;
    }

    .BoxSortSelectContainer {
        display: block;
        text-align: left;
        margin-top: 0px;
        width: 240px;
        height: 100%;
        
    }
}