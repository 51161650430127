.BaseScreen {
    width: 100%;
    height: calc(100vh - 50px);
    display: block;
    @include alignHorizontallyCenter;
    max-width: 2300px;
    margin: auto;
    margin-top: 0px; 
    margin-bottom: 0px;
    overflow: overlay;
}


// .BaseScreen::-webkit-scrollbar {
//     width: 4px;
//     display: none;
// }

// .BaseScreen:hover::-webkit-scrollbar {
//     width: 4px;
//     display: block;
// }

// .BaseScreen::-webkit-scrollbar-track {
//     background: #DFDFDF;
//     border-radius: 4px;
//     height: 100%;
// }

// .BaseScreen::-webkit-scrollbar-thumb {
//     background: #777777;
//     border-radius: 4px;
//     -webkit-box-shadow: inset 0 0 4px     rgba(0,0,0,0.3);
// }

.TableScreen {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: block;
    overflow: auto;
    @include alignHorizontallyCenter;
}

.ScreenDetailScreen {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    @include alignHorizontallyCenter;
}

.BoxPaddedContent {
    @include alignHorizontallyCenter;
    width: 80%;
    margin: auto;
}

@media(min-width: 700px) {
    .ScreenDetailScreen {
        padding-left: 10px;
        padding-right: 10px;
    }
}