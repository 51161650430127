

.DataTableContainerHousing {
    border: 1px solid $darkBlueColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    height: auto;
    overflow: hidden;
    background: $whiteColor;
    overflow: visible;

    table {
        width: 100%;
    }
}

.DataTableContainerHeaderHousing {
    padding: 10px;
    display: flex;
    justify-content: space-between;

}

.DataTableContainerHeaderLeftContentsHousing {
    text-align: left;
    display: flex;
    justify-content: flex-start;
}

.DataTableContainerHeaderRightContentsHousing {
    text-align: left;
    display: block;
}

.DataListContainerHousing {
    border: 1px solid $darkBlueColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    height: auto;
    overflow: hidden;
    background: $whiteColor;
    overflow: visible;
    max-width: 1000px;
}

.DataListContainerHeaderHousing {
    padding: 10px;
    display: block;

}

.DataTableContainerHeaderLeftContentsHousing {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.DataListContainerHeaderRightContentsHousing {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

// @media (min-width: 1400px) {
//     .DataTableContainerHousing {
//         margin-left: auto;
//         margin-right: auto;
//     }

// }

// @media (min-width: $minTabletWidth) {

//     .DataTableContainerHeaderRightContentsHousing {
//         display: flex;
//         justify-content: space-between;
//     }
// }