// Margin Area

.Margin--Horizontal--Auto {
    margin-left: auto;
    margin-right: auto;
}

.Margin--Horizontal--0 {
    margin-left: 0px;
    margin-right: 0px;
}

.Margin--Horizontal--10 {
    margin-left: 5px;
    margin-right: 5px;
}

.Margin--Horizontal--15 {
    margin-left: 10px;
    margin-right: 10px;
}

.Margin--Horizontal--20 {
    margin-left: 15px;
    margin-right: 15px;
}

.Margin--Vertical--Auto {
    margin-top: auto;
    margin-bottom: auto;
}

.Margin--Vertical--0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.Margin--Vertical--8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.Margin--Vertical--10 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Margin--Vertical--12 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.Margin--Vertical--15 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.Margin--Vertical--20 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.Margin--Top--0 {
    margin-top: 0px;
}

.Margin--Top--5 {
    margin-top: 5px;
}

.Margin--Top--10 {
    margin-top: 5px;
}

.Margin--Top--12 {
    margin-top: 8px;
}

.Margin--Top--15 {
    margin-top: 10px;
}

.Margin--Top--20 {
    margin-top: 15px;
}

.Margin--Top--30 {
    margin-top: 30px;
}

.Margin--Bottom--Auto {
    margin-bottom: auto;
}

.Margin--Bottom--0 {
    margin-bottom: 0px;
}

.Margin--Bottom--10 {
    margin-bottom: 5px;
}

.Margin--Bottom--12 {
    margin-bottom: 8px;
}

.Margin--Bottom--15 {
    margin-bottom: 10px;
}

.Margin--Bottom--20 {
    margin-bottom: 15px;
}

.Margin--Left--5 {
    margin-left: 5px;
}

.Margin--Left--8 {
    margin-left: 8px;
}

.Margin--Left--10 {
    margin-left: 10px;
}

.Margin--Left--15 {
    margin-left: 15px;
}

.Margin--Left--20 {
    margin-left: 20px;
}

.Margin--Right--5 {
    margin-right: 5px;
}

.Margin--Right--10 {
    margin-right: 10px;
}

.Margin--Right--15 {
    margin-right: 10px;
}



@media(min-width: $minTabletWidth) {
    .Margin--Horizontal--10 {
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .Margin--Horizontal--15 {
        margin-left: 15px;
        margin-right: 15px;
    }
    
    .Margin--Horizontal--20 {
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .Margin--Vertical--10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .Margin--Vertical--12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    
    .Margin--Vertical--15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .Margin--Vertical--20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .Margin--Top--10 {
        margin-top: 10px;
    }
    
    .Margin--Top--12 {
        margin-top: 12px;
    }
    
    .Margin--Top--15 {
        margin-top: 15px;
    }
    
    .Margin--Top--20 {
        margin-top: 20px;
    }
    
    .Margin--Bottom--10 {
        margin-bottom: 10px;
    }
    
    .Margin--Bottom--12 {
        margin-bottom: 12px;
    }
    
    .Margin--Bottom--15 {
        margin-bottom: 15px;
    }
    
    .Margin--Bottom--20 {
        margin-bottom: 20px;
    }

    .Margin--Right--15 {
        margin-right: 15px;
    }
    
}



// Padding Area

.Padding--Horizontal--Auto {
    padding-left: auto;
    padding-right: auto;
}

.Padding--Horizontal--0 {
    padding-left: 0px;
    padding-right: 0px;
}

.Padding--Horizontal--10 {
    padding-left: 8px;
    padding-right: 8px;
}

.Padding--Horizontal--12 {
    padding-left: 10px;
    padding-right: 10px;
}

.Padding--Horizontal--15 {
    padding-left: 12px;
    padding-right: 12px;
}

.Padding--Horizontal--20 {
    padding-left: 15px;
    padding-right: 15px;
}

.Padding--Horizontal--20x {
    padding-left: 10px;
    padding-right: 10px;
}

.Padding--Horizontal--30 {
    padding-left: 20px;
    padding-right: 20px;
}

.Padding--Vertical--Auto {
    padding-top: auto;
    padding-bottom: auto;
}

.Padding--Vertical--0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.Padding--Vertical--5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.Padding--Vertical--8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.Padding--Vertical--10 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.Padding--Vertical--12 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Padding--Vertical--15 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.Padding--Vertical--20 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.Padding--Top--0 {
    padding-top: 0px;
}

.Padding--Top--10 {
    padding-top: 10px;
}

.Padding--Top--5 {
    padding-top: 5px;
}

.Padding--Top--10 {
    padding-top: 10px;
}

.Padding--Bottom--10 {
    padding-bottom: 10px;
}

.Padding--Bottom--20 {
    padding-bottom: 20px;
}



@media(min-width: $minTabletWidth) {
    .Padding--Horizontal--10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .Padding--Horizontal--12 {
        padding-left: 12px;
        padding-right: 12px;
    }
    
    .Padding--Horizontal--15 {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .Padding--Horizontal--20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .Padding--Horizontal--20x {
        padding-left: 20px;
        padding-right: 20px;
    }

    .Padding--Horizontal--30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    
    .Padding--Vertical--10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .Padding--Vertical--12 {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    
    .Padding--Vertical--15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    .Padding--Vertical--20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}



// Spacers Area

.Spacer--0 {
    height: 0px;
}

.Spacer--5 {
    height: 3px;
}


.Spacer--10 {
    height: 5px;
}

.Spacer--15 {
    height: 10px;
}

.Spacer--20 {
    height: 15px;
}

.Spacer--30 {
    height: 20px;
}

.Spacer--40 {
    height: 30px;
}

.Spacer--Width--5 {
    width: 3px;
}


.Spacer--Width--10 {
    width: 5px;
}

.Spacer--Width--15 {
    width: 10px;
}

.Spacer--Width--20 {
    width: 15px;
}


@media(min-width: $minTabletWidth) {
    .Spacer--5 {
        height: 5px;
    }


    .Spacer--10 {
        height: 10px;
    }
    
    .Spacer--15 {
        height: 15px;
    }
    
    .Spacer--20 {
        height: 20px;
    }

    .Spacer--30 {
        height: 30px;
    }
    
    .Spacer--40 {
        height: 40px;
    }
}




// Display Area

.Display--Block {
    display: block;
}

.Display--Flex {
    display: flex;
}

.Flex--SpaceBetween {
    justify-content: space-between;
}

.Flex--Start {
    justify-content: flex-start;
}

.Flex--End {
    justify-content: flex-end;
}


// Border

.Border--None {
    border: none;
}

.Border--All--1 {
    border: 1px solid;
}


.Border--All--2 {
    border: 2px solid;
}

.Border--Bottom--1 {
    border-bottom: 1px solid;
}

.Border--Top--1 {
    border-top: 1px solid;
}

// Border Color

.BorderColor--BlueTint {
    border-color: $blueColor1;
}

.BorderColor--DarkBlueTint {
    border-color: $darkBlueColor;
}

.BorderColor--DarkGreyTint {
    border-color: $darkBlueColor1;
}

.BorderColor--ButtonGreyTint {
    border-color: $greyColor7;
}

.BorderColor--BlackTint {
    border-color: $blackColor;
}

.BorderColor--WhiteTint {
    border-color: $whiteColor;
}

.BorderColor--RedTint {
    border-color: $redColor;
}

.BorderHover--All--Blue {
    border-color: $darkBlueColor;
    border: 1px solid;
    padding-top: 1px;
    padding-bottom: 1px;

    &:hover {
        padding-top: 0px;
        padding-bottom: 0px;
        border-color: $blueColor1;
        border: 2px solid $blueColor1;
    }

}

.BorderHover--All--Red {
    border-color: $redColor;
    border: 1px solid $redColor;
    padding-top: 1px;
    padding-bottom: 1px;

    &:hover {
        padding-top: 0px;
        padding-bottom: 0px;
        border-color: $redColor;
        border: 2px solid $redColor;
    }

}


// Border Radius

.BorderRadius--All--5 {
    border-radius: 5px;
}

.BorderRadius--All--10 {
    border-radius: 10px;
}

.BorderRadius--All--15 {
    border-radius: 15px;
}

.BorderRadius--All--Half {
    border-radius: 50%;
}

.BorderRadius--Top--10 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.BorderRadius--Top--15 {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.BorderRadius--Bottom--10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.BorderRadius--Bottom--15 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}



// Background Color

.BackgroundColor--LightGrey {
    background-color: #E3E7EB;
}

.BackgroundColor--WhiteTint {
    background-color: $whiteColor;
}

.BackgroundColor--DirtyWhite {
    background-color: $dirtyWhiteColor;
}

.BackgroundColor--DarkBlueTint {
    background-color: $darkBlueColor;
}

.BackgroundColor--BlueTint {
    background-color: $blueColor1;
}

.BackgroundColor--GreenTint {
    background-color: $greenColor;
}

.BackgroundColor--GreyTint {
    background-color: $greyColor3;
}

.BackgroundColor--GreyTint2 {
    background-color: $greyColor2;
}

.BackgroundColor--None {
    background-color: transparent;
}



// Box Shadow

.BoxShadow--1 {
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
}

.BoxShadow--0-5 {
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;

    &:hover {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}


@media(min-width: $minTabletWidth) {
    .BoxShadow--1 {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }
}


// Width 

.Width--Auto {
    width: 100%;
}

.Width--Full {
    width: 100%;
}

.Width--Full-10 {
    width: calc(100% - 10px);
}

.Width--Full-20 {
    width: calc(100% - 20px);
}

.Width--Full-40 {
    width: calc(100% - 40px);
}


// CSS Animations

.Animation--Fade-In {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
}

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
     } 
} 

.Animation--Height-Ease {
    animation: heightEase 0.5s;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
}

@keyframes heightEase { 
    0% { 
        height: 0px;
    } 
    100% { 
        height: auto;
     } 
} 


// Overflow

.Overflow--Hidden {
    overflow: hidden;
}

.Overflow--Auto {
    overflow: auto;
}



// Pointers

.Pointer--Cursor {
    cursor: pointer;
}


// Other

.LineHeight--Inherit {
    line-height: inherit;
}

.LineHeight--Normal {
    line-height: normal;
}

.TextBreak {
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.TextDecoration--Underline {
    text-decoration: underline;
}

.Opacity--100 {
    opacity: 1;
}

.Opacity--070 {
    opacity: 0.7;
}

.ForceSelectText {
    -webkit-user-select: all;  /* Chrome 49+ */
    -moz-user-select: all;     /* Firefox 43+ */
    -ms-user-select: all;      /* No support yet */
    user-select: all;          /* Likely future */   
}