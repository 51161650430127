// Main Box Style
.Box {
    border: 1px solid $darkBlueColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    margin: auto;
    height: auto;
    overflow: hidden;
    background: $whiteColor;
}

// Dashboard Box 1
.BaseBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $whiteColor;
}

// Desktop Title Box
.DesktopTitleBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 600px;
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $greyColor2;
}

//Forgot Password Box
.ForgotPasswordBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 500px;
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: $whiteColor;
}

//Link Box
.LinkBox {
    margin: 10px;
    height: auto;
    @include alignHorizontallyCenter;
    max-width: 500px;
    background: $whiteColor;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.50);
    border-radius: 10px;

    button {
        background-color: transparent;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.TextContainer {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

//Log In Box
.LogInBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 500px;
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: $whiteColor;
}

//Settings Box
.SettingsBox {
    @extend .Box;
    @include alignHorizontallyCenter;
    background: $whiteColor;
    overflow: visible;
}

//Action Box
.ActionBox {
    background: $whiteColor;
    border: 1px solid $darkBlueColor;
    box-shadow: 0.5px 0.5px 0.5px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    height: auto;
    overflow: hidden;
    margin: auto;
}

.ActionBox_Header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
}

//New Item Box
.NewItemBox {
    z-index: -1000;
    background: $whiteColor;
    border: 1px solid $darkBlueColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    height: auto;
    overflow: visible;
    margin: auto;
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
    height: auto;
    max-width: 600px;
    
}

.NewItemBox_Header {
    padding: 15px;
    padding-bottom: 20px;
    display: block;
}

.NewItemBox_Contents {
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
}

.AlertConfirmationPopUpBox {
    z-index: -1000;
    background: $whiteColor;
    border: 1px solid $darkBlueColor;
    box-shadow: 1px 1px 1px 0 $boxShadowColor;
    border-radius: 10px;
    text-align: center;
    display: block;
    height: auto;
    overflow: hidden;
    margin: auto;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    height: auto;
    max-width: 600px;
    
}


.NewItemBox_Footer {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
}

//Sign Up Box
.SignUpBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 500px;
    margin-top: 35px;
    padding-top: 10px;
    padding-bottom: 20px;
    background: $whiteColor;
}

//White Box
.WhiteBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $whiteColor;
}

.SetupAccountBox {
    @extend .Box;
    @include setSize(85%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: $whiteColor;
    overflow: visible;
}

.CloseAccountBox {
    @extend .Box;
    @include setSize(90%, auto);
    @include alignHorizontallyCenter;
    width: 85%;
    max-width: 700px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: $whiteColor;
    overflow: visible;
}


//Legal Box
.LegalBox {
    @extend .Box;
    @include alignHorizontallyCenter;
    width: 80%;
    max-width: 900px;
    margin-top: 50px;
    padding: 15px;
    background: $whiteColor;
}

// Box Lines
.SettingsBoxLine {
    height: 1px;
    display: block;
    border: 0;
    border-top: 1px solid $darkBlueColor;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: 0px;
}

.BoxTitleMargin {
    margin: 15px;
}


@media (min-width: $minTabletWidth) {

    .BaseBox {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .LegalBox {
        padding: 30px;
    }

    .DesktopTitleBox {
        margin-top: 50px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .ForgotPasswordBox {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .LinkBox {
        height: 150px;
    }

    .LogInBox {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    // .SettingsBox {

    // }

    .ActionBox {
        box-shadow: 1px 1px 1px 0 $boxShadowColor;
    }

    .ActionBox_Header {
        padding: 15px 20px;
    }

    .BoxTitleMargin {
        margin: 20px;
    }

    .SignUpBox {
        padding-top: 15px;
        padding-bottom: 30px;
        margin-top: 50px;
    }

    .WhiteBox {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .SettingsBoxLine {
        margin-bottom: 0px;
    }
    
    .NewItemBox {
        margin-top: auto;
    }

    .NewItemBox_Header {
        padding: 20px;
        padding-top: 15px;
    }

    .NewItemBox_Contents {
        padding-top: 15px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .NewItemBox_Footer {
        padding: 15px 20px;
        display: flex;
        justify-content: flex-end;
    }

    .AlertConfirmationPopUpBox {
        margin-top: auto;
    }
}

@media (min-height: 900px) {
    .NewItemBox {
        margin-top: 250px;
    }

    .AlertConfirmationPopUpBox {
        margin-top: 250px;
    }
}